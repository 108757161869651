import { Box, FormControl, Radio, RadioGroup } from '@mui/material';
import React, { useEffect, useState } from 'react'
import AssessmentSearchForm from './AssessmentSearchForm';
import ErrorMessage from '../../components/ui/errorMessage/ErrorMessage';
import SpectrTablePagination from '../../components/ui/spectr-table-pagination/SpectrTablePagination';
import { ASSESSMENT_SEARCH_TAB, LEGAL_SEARCH_TAB } from '../../utils/constants/tabConstants';
import MatchStatus from '../../components/ui/matchStatus/MatchStatus';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { AssessmentSearchData, resetAssessmentSearchState, searchByAssessmentSearch, setAssessmentSearchToState } from './AssessmentSearchSlice';
import { AssesementSearchFormType } from '../../entities/Types/AssesementSearchFormType';
import { getSessionStorage, setSessionStorage, specialCharacterFormatting } from '../../utils/common/commonUtils';
import { AssessmentSearchRequest } from '../../entities/ApiModel/AssessmentSearchRequest';
import { AssessmentSearchFormErrorMessages, EXCEPTION_MATCH_TEXT, keyEntriesPerPage, keyPageNo, MULTIPLE_MATCH_RESULT_TEXT, MULTIPLE_MATCH_TEXT, NO_COVERAGE_TEXT, NO_MATCH_FOUND, SINGLE_MATCH_TEXT } from '../../utils/constants/constants';
import MatchCodes from '../../utils/enums/MatchCodes';
import LoadingProgressModel from '../../components/ui/circularProgress/LoadingProgress';
import { TabPanel } from '@mui/lab';
import { StyledRadioButton } from '../../components/StyledComponents/StyledRadioButton';
import { ValidationText } from '../../components/StyledComponents/CommonControls';
import styles from './assessmentSearch.module.css';
import { tabIdentifier } from '../../components/ui/propertyTable/tabIdentifierSlice';
import LegalSearch from '../legalSearch/LegalSearch';

function AssessmentSearch() {

    const [assessmentErrorMessage, setAssessmentErrorMessage] = useState("");
    const [assessmentMatchCode, setAssessmentMatchCode] = useState<string>("");
    const [assessmentMatchText, setAssessmentMatchText] = useState<string>("");
    const assessmentSearchState = useAppSelector(AssessmentSearchData);
    const [resultText, setResultText] = useState<string>("");
    const [isLoading, setLoading] = useState(false);
    const dispatch = useAppDispatch();
    const [isPolicyLegalSearch, setPolicyLegalSearch] = React.useState(false);
    const [isAssesementSearch, setAssesementSearch] = React.useState(true);
    const tabState = useAppSelector(tabIdentifier);

    useEffect(() => {
        if (tabState && tabState.subTabName === ASSESSMENT_SEARCH_TAB) {
            setTimeout(function () {
                setAssesementSearch(true);
                setPolicyLegalSearch(false);
            }, 0);
        }
        else if (tabState.tabName === LEGAL_SEARCH_TAB) {
            setTimeout(function () {
                setAssesementSearch(false);
                setPolicyLegalSearch(true);
            }, 0);
        }
    }, []);

    async function handleAssesementSubmit(formData: AssesementSearchFormType) {
        if (!formData)
            return;
        dispatch(resetAssessmentSearchState());

        const defaultPageNo = 1;
        const defaultRowsPerPage = 10;
        setSessionStorage(keyPageNo + ASSESSMENT_SEARCH_TAB, defaultPageNo);
        let page = defaultPageNo;//parseInt(getSessionStorage(keyPageNo + LEGAL_SEARCH_TAB) ?? defaultPageNo.toString());
        let rowsPerPage = parseInt(getSessionStorage(keyEntriesPerPage + ASSESSMENT_SEARCH_TAB) ?? defaultRowsPerPage.toString())
        let request: AssessmentSearchRequest = {
            state: formData.stateField.id ?? "",
            fips: formData.countyField.fips ?? "",
            county: formData.countyField.county ?? "",
            lot_number_from: specialCharacterFormatting(formData.LotFrom ?? ""),
            lot_number_to: specialCharacterFormatting(formData.LotTo ?? ""),
            legal_block: formData.Block ?? "",
            legal_district: formData.District ?? "",
            legal_unit: formData.Unit ?? "",
            legal_subdivision_name: formData.SubDivision ?? "",
            legal_phase_number: formData.PhaseNumber ?? "",
            legal_tract_number: formData.TractNumber ?? "",
            offset_value: ((page - 1) * rowsPerPage).toString(),
            fetchnext_value: ((page - 1) * rowsPerPage + rowsPerPage).toString()
        }

        formData.pageNo = page;
        //setMatchCode("");
        setAssessmentMatchCode("");
        setLoading(true);

        dispatch(searchByAssessmentSearch(request));
        dispatch(setAssessmentSearchToState(JSON.parse(JSON.stringify(formData))));
    }

    const handleAssessmentChangeRowsPerPage = (count: number) => {
        dispatch(resetAssessmentSearchState());
    }

    const handleAssessmentChangePage = (newPage: number) => {
        //setPageNo(newPage);
        //dispatch(resetLegalSearchState());
    }

    useEffect(() => {
        if (assessmentSearchState
            && assessmentSearchState.property
            && assessmentSearchState.property.match_code !== "" && !assessmentSearchState.property.isExecuting) {
            setLoading(false)
            setAssessmentMatchCode(assessmentSearchState.property.match_code);
        }

        if (assessmentSearchState.status === "failed") {
            setLoading(false);
        }
    }, [assessmentSearchState]);

    useEffect(() => {
        if (assessmentMatchCode && assessmentMatchCode !== "") {
            switch (assessmentMatchCode) {
                case MatchCodes.NO_MATCH.toString(): {
                    setAssessmentMatchText(NO_MATCH_FOUND);
                    break;
                }
                case MatchCodes.UNIQUE_MATCH.toString(): {
                    setAssessmentMatchText(SINGLE_MATCH_TEXT);
                    break;
                }
                case MatchCodes.MULTIPLE_MATCH.toString(): {
                    setAssessmentMatchText(MULTIPLE_MATCH_TEXT);
                    setResultText(MULTIPLE_MATCH_RESULT_TEXT);
                    break;
                }
                case MatchCodes.NO_COVERAGE.toString(): {
                    setAssessmentMatchText(NO_COVERAGE_TEXT);
                    break;
                }
                case MatchCodes.EXCEPTION.toString(): {
                    setAssessmentMatchText(EXCEPTION_MATCH_TEXT);
                    break;
                }
                default: {
                    setAssessmentMatchText("");
                    setResultText("");
                }
            }
        } else {
            setAssessmentMatchText("");
            setResultText("");
        }
    }, [assessmentMatchCode]);

    async function handleAssessmentFetchNext(page: number, pageNumber: string, noOfRows: string) {
        setLoading(true);
        let request: AssessmentSearchRequest = {
            state: assessmentSearchState.formFields?.stateField.id ?? "",
            fips: assessmentSearchState?.formFields?.countyField.fips ?? "",
            county: assessmentSearchState?.formFields?.countyField.county ?? "",
            lot_number_from: specialCharacterFormatting(assessmentSearchState?.formFields?.LotFrom ?? ""),
            lot_number_to: specialCharacterFormatting(assessmentSearchState?.formFields?.LotTo ?? ""),
            legal_block: assessmentSearchState?.formFields?.Block ?? "",
            legal_district: assessmentSearchState?.formFields?.District ?? "",
            legal_unit: assessmentSearchState?.formFields?.Unit ?? "",
            legal_subdivision_name: assessmentSearchState?.formFields?.SubDivision ?? "",
            legal_phase_number: assessmentSearchState?.formFields?.PhaseNumber ?? "",
            legal_tract_number: assessmentSearchState?.formFields?.TractNumber ?? "",
            offset_value: pageNumber.toString(),
            fetchnext_value: noOfRows.toString(),
        }
        setAssessmentMatchCode("");
        setAssessmentMatchText("");
        let formData = { ...assessmentSearchState?.formFields, "pageNo": page }
        dispatch(searchByAssessmentSearch(request));
        dispatch(setAssessmentSearchToState(formData));
    }

    const handleAssessmentSearchFormError = (errors: any) => {
        if (errors.stateField?.id?.message === "Required" &&
            errors.countyField?.fips?.message === "Required") {
            setAssessmentErrorMessage(AssessmentSearchFormErrorMessages.NoFieldError);
        }
        else if (errors.countyField?.fips?.message === "Required") {
            setAssessmentErrorMessage(AssessmentSearchFormErrorMessages.CountyRequiredError);
        }
        else if (errors.fieldValidationError?.type === "Empty_Form_Value") {
            setAssessmentErrorMessage(AssessmentSearchFormErrorMessages.MinimumFieldsError);
        }
        else if (errors.subdivision_lot_from?.type === "Greater_Value_Error" || errors.subdivision_lot_to?.type === "Greater_Value_Error") {
            setAssessmentErrorMessage(AssessmentSearchFormErrorMessages.LotFromGreaterValue);
        }
        else if (Object.keys(errors).length > 0) {
            const keys = Object.keys(errors);
            let message = keys?.length > 0 ? errors[keys[0]]?.message : "";
            setAssessmentErrorMessage(message);
        }
        else {
            setAssessmentErrorMessage("")
        }
    }


    async function handleAssessmentClearSearch() {
        setAssessmentMatchCode("");
    }

    let columns = [
        { id: 'apn', label: 'APN', minWidth: 180 },
        { id: 'ownerName', label: 'OWNER NAME', minWidth: 290 },
        {
            id: 'address',
            label: 'ADDRESS',
            minWidth: 210,
        },
        {
            id: 'city',
            label: 'CITY',
            minWidth: 130,
        },
        {
            id: 'state',
            label: 'STATE',
            minWidth: 60,
        },
        {
            id: 'formattedZip',
            label: 'ZIP',
            minWidth: 135,
            format: (value: number) => value.toFixed(2),
        },
        {
            id: 'landUse',
            label: 'LAND USE',
            minWidth: 200,
        },
        {
            id: 'assessmentLegal',
            label: 'ASSESSMENT LEGAL',
            minWidth: 195,
        }
    ];

    const handleAssesementSearchClick = () => {
        setPolicyLegalSearch(false)
        setAssesementSearch(true)
    }

    const handlePolicySearchClick = () => {
        setAssesementSearch(false)
        setPolicyLegalSearch(true)
    }

    return (
        <>
            {isLoading &&
                <LoadingProgressModel />
            }
            <TabPanel value="legalSearchTab" sx={{ padding: 0 }}>
                <Box className={styles.boxContent}>
                    <FormControl>
                        <RadioGroup
                            row
                            name="row-radio-buttons-group">
                            <StyledRadioButton minWidth="210px" value="assessmentLegalSearch" sx={{ paddingRight: "15px" }}
                                control={<Radio />} checked={isAssesementSearch} onChange={handleAssesementSearchClick} label="Assessment Legal Search" />
                            <StyledRadioButton value="policyLegalSearch" onChange={handlePolicySearchClick}
                                control={<Radio />} checked={isPolicyLegalSearch} label="Policy Legal Search" />
                        </RadioGroup>
                    </FormControl>
                    <Box sx={{ marginTop: '15px', marginBottom: '10px' }}>
                        <ValidationText>*State, County and one additional field required</ValidationText>
                    </Box>
                </Box>
                {
                    isAssesementSearch &&
                    <>

                        <Box className={styles.boxLayout}>
                            <AssessmentSearchForm
                                raiseError={handleAssessmentSearchFormError}
                                onSubmit={handleAssesementSubmit}
                                onClear={handleAssessmentClearSearch}
                                formFields={assessmentSearchState?.formFields} />
                            {
                                assessmentErrorMessage
                                &&
                                <ErrorMessage errorMessageText={assessmentErrorMessage} />
                            }
                        </Box>


                    </>
                }
                {
                    isPolicyLegalSearch &&
                    <>
                        <LegalSearch />
                    </>
                }

                {
                    isAssesementSearch
                    && assessmentMatchCode
                    && assessmentSearchState
                    && assessmentSearchState.property
                    && assessmentSearchState.property.AssessmentSearch
                    && Object.keys(assessmentSearchState.property.AssessmentSearch).length > 0
                    &&
                    < MatchStatus matchCode={assessmentMatchCode} matchText={assessmentMatchText} resultText={resultText} />
                }
                {
                    isAssesementSearch
                    && assessmentSearchState
                    && assessmentSearchState.property
                    && assessmentSearchState.property.AssessmentSearch
                    && !assessmentSearchState.property.isExecuting
                    && Object.keys(assessmentSearchState.property.AssessmentSearch).length > 0
                    && (Object.keys(assessmentSearchState.property.AssessmentSearch).length > 0 && assessmentSearchState.property.AssessmentSearch[1].length > 0)
                    &&
                    <div>
                        <Box sx={{ mt: '15px', width: '100%'}}>
                            <SpectrTablePagination
                                tabName={ASSESSMENT_SEARCH_TAB}
                                navigationTabName={LEGAL_SEARCH_TAB}
                                columns={columns}
                                rows={[]}
                                propertyList={assessmentSearchState.property.AssessmentSearch}
                                handleChangePage1={handleAssessmentChangePage}
                                handleChangeRowsPerPage1={handleAssessmentChangeRowsPerPage}
                                fetchNextData={handleAssessmentFetchNext}
                                totalRowCount={assessmentSearchState.property.total_count ?? 0} />
                        </Box>
                    </div>
                }
            </TabPanel >
        </>
    )
}

export default AssessmentSearch